import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <Box
      sx={{
        padding: { xs: 2, lg: 8 },
        paddingBottom: { xs: 20, lg: undefined },
        marginBottom: 8,
        borderRadius: 2,
        position: 'relative',
        background: { xs: 'linear-gradient(to bottom, #FFF 50%, transparent)', lg: 'linear-gradient(to right, #FFF 25%, transparent)' },
      }}
    >
      <Box width={{ lg: '50%' }}>
        <Typography variant="h1" gutterBottom>
          TasteIndex, enabling customers with the navigation system to find the food and beverages they truly love.
        </Typography>
        <Typography gutterBottom>
          The 45-second tool that improves customer value powered by sensory intent science, chemistry and AI
        </Typography>
      </Box>

      <Button component={Link} to="/questionnaire" variant="contained">
        Do the test
      </Button>

      <img
        src="https://images.squarespace-cdn.com/content/v1/55a3894be4b0bb17f7b55958/1436870655634-ZI4KPDNSOS32LSQ1BNDZ/Collected-food-final-small.jpg"
        alt=""
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
    </Box>
  );
}

export default Hero;
