import { Box, Typography } from '@mui/material';

function MatchPercentageIndicator({ percentage }: { percentage: number | undefined }) {
  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      marginY: 2,
      alignItems: 'center',

    }}
    >

      <Typography
        variant="h2"
        marginY={0}
        fontSize={{ xs: '1rem' }}
        style={{

          width: '3rem',
          height: '3rem',
          lineHeight: '2.5rem',
          borderRadius: '200px',
          position: 'relative',
          borderWidth: '4px',
          borderStyle: 'solid',
          borderColor: '#333',
          textAlign: 'center',
        }}
      >
        {percentage ? Math.round(percentage) : '?'}

        <Typography
          component="span"
          variant="h2"
          marginY={0}
          fontSize={{ xs: '1rem' }}
          style={{
            position: 'absolute',
            top: -4,
            right: -4,
            fontWeight: 'bold',

            textShadow: `
    1px 1px 0 white,
    -1px 1px 0 white,
    -1px -1px 0 white,
    1px -1px 0 white`,
          }}
        >
          %
        </Typography>
      </Typography>

      <Typography variant="h2" marginY={0} fontSize={{ xs: '1rem' }}>
        match
      </Typography>
    </Box>
  );
}

export default MatchPercentageIndicator;
