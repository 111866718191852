import { Container } from '@mui/material';
import metrics from '../assets/metrics.png';

function Metrics() {
  return (
    <Container sx={{ textAlign: 'center' }}>
      <img src={metrics} alt="" width="80%" />
    </Container>
  );
}

export default Metrics;
