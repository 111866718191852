import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, sans-serif',
    allVariants: {
      fontSize: '16px',
    },
    h1: {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '24px',
    },
  },
  palette: {
    primary: {
      main: '#F37920',
      contrastText: 'white',
    },
    secondary: {
      main: '#050505',
    },
    background: {
      default: 'white',
    },
    text: {
      primary: '#363636',
    },
  },

});

export default theme;
