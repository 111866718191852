import './App.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Product from './pages/Product';
import Home from './pages/Home';
import ResponsiveAppBar from './components/Header';
import theme from './theme';
import Footer from './components/Footer';
import MyMatches from './pages/MyMatches';
import Metrics from './pages/Metrics';
import Questionnaire from './pages/Questionnaire';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <ResponsiveAppBar />

        <main style={{ paddingTop: '2rem' }}>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products/:sku" element={<Product />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route path="/my-matches" element={<MyMatches />} />
            <Route path="/metrics" element={<Metrics />} />
          </Routes>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>

    </ThemeProvider>
  );
}

export default App;
