import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MatchPercentageIndicator from '../components/MatchPercentageIndicator';
import ProductLeaderboard from '../components/ProductLeaderboard';
import getProductsWithMatchRating from '../products';

function MyMatches() {
  const [products, setProducts] = useState<Array<any> | undefined>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    products?.[0]?.attributes.category ?? '',
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  const location = useLocation();

  useEffect(() => {
    const getProducts = async () => {
      const productsWithMatches = await getProductsWithMatchRating(
        new URLSearchParams(location.search),
      );

      setProducts(productsWithMatches);
      setSelectedCategory(productsWithMatches?.[0]?.attributes.category ?? '');
    };

    getProducts();
  }, [location.search]);

  const filteredProducts = useMemo(
    () => (products ?? []).filter(
      (product) => product.attributes.category === selectedCategory,
    ),
    [products, selectedCategory],
  );

  const [productFirst, productSecond, productThird, ...productRest] = filteredProducts;

  if (!products) {
    return (
      <Container>
        <Typography variant="h1">
          Do you want to see your personal matching?
        </Typography>
        <p>After finishing the test you can see your personal matches here!</p>

        <Grid
          container
          spacing={0}
          sx={{ alignItems: 'flex-end', textAlign: 'center', marginTop: 8 }}
        >
          {[...Array(3).keys()].map((i) => (
            <Grid key={`match-${i}`} xs={12} md={4}>
              <Card
                sx={{
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                }}
              >
                <CardMedia
                  component="img"
                  height="160"
                  image="https://www.wijnvoordeel.nl/media/catalog/product/e/1/e105309_2021_oro_italiano_ultima_edizione_rosso_italia.png?quality=95&fit=bounds&height=400&width=500&bg-color=FFF"
                  sx={{
                    objectFit: 'contain',
                    filter: 'blur(8px)',
                    opacity: 0.5,
                  }}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h2" style={{ filter: 'blur(8px)' }}>
                    A product that fits you!
                  </Typography>

                  <MatchPercentageIndicator percentage={undefined} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Button
          component={Link}
          to="/questionnaire"
          variant="contained"
          size="large"
        >
          Do the test
        </Button>
      </Container>
    );
  }

  if (!products.length) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <>
      <Box sx={{ borderBottom: '1px solid gray', marginBottom: 4 }}>
        <Container className="leaderboard" sx={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            sx={{
              marginBottom: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Your
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedCategory}
                onChange={handleChange}
                autoWidth
                style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                className="pulse"
              >
                {[
                  ...new Set(
                    products.map((product) => product.attributes.category),
                  ),
                ].sort().map((category) => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
            matches
          </Typography>

          {productFirst && productSecond && productThird && (
            <Grid
              container
              spacing={2}
              sx={{ alignItems: 'flex-end', textAlign: 'center' }}
            >
              <Grid xs={4}>
                <ProductLeaderboard product={productSecond} />

                <Box
                  sx={{
                    height: 160,
                    backgroundColor: 'silver',
                    opacity: 1,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    textAlign: 'center',
                    padding: 4,
                  }}
                  className="grow grow-2"
                >
                  <Typography variant="h1">2nd</Typography>
                </Box>
              </Grid>

              <Grid xs={4}>
                <ProductLeaderboard product={productFirst} />

                <Box
                  sx={{
                    height: 200,
                    backgroundColor: 'gold',
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    textAlign: 'center',
                    padding: 4,
                  }}
                  className="grow grow-1"
                >
                  <Typography variant="h1">1st</Typography>
                </Box>
              </Grid>

              <Grid xs={4}>
                <ProductLeaderboard product={productThird} />

                <Box
                  sx={{
                    height: 120,
                    backgroundColor: '#cd7f32',
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    textAlign: 'center',
                    padding: 4,
                  }}
                  className="grow grow-3"
                >
                  <Typography variant="h1">3rd</Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>

      {productRest.length > 0 && (
        <Container>
          <Typography variant="h2" sx={{ marginBottom: 8 }}>
            And many more which match your taste profile...
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: 'flex-end', textAlign: 'center' }}
          >
            {productRest.map((product) => (
              <Grid key={product.sku} xs={12} md={4}>
                <Link
                  to={`/products/${product.sku}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Card
                    sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      image={product.image}
                      sx={{ objectFit: 'contain' }}
                    />
                    <CardContent sx={{ textAlign: 'left' }}>
                      <Typography variant="h2">{product.name}</Typography>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <MatchPercentageIndicator
                          percentage={product.matchPercentage}
                        />

                        <Typography>
                          {/* &#36; */}
                          &euro;
                          {product.price.toFixed(2)}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default MyMatches;
