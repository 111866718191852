import {
  AddShoppingCart,
  LocalShipping,
  Public,
  RestartAlt,
  Shield,
  WineBar,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import { useRequest } from 'ahooks';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import MatchPercentageIndicator from '../components/MatchPercentageIndicator';

import { getProductMatch } from '../products';
import productInfo from '../products/productInfo';
import reviews from '../reviews.json';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function Product() {
  const { sku } = useParams();
  const [searchParams] = useSearchParams();
  const product = useMemo(() => productInfo.find((p) => p.sku === sku), [sku]);
  const { data } = useRequest(
    () => getProductMatch(sku, searchParams),
    { refreshDeps: [searchParams] },
  );

  if (!product) return <>404</>;

  return (
    <Container>
      <Grid container spacing={4} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={6}>
          <img
            src={product.image}
            width="100%"
            height="300"
            style={{ objectFit: 'contain' }}
            alt={product.name}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h1">{product.name}</Typography>

          <Typography variant="h2">{product.attributes?.country}</Typography>

          <Rating name="simple-controlled" value={5} readOnly size="large" />

          <MatchPercentageIndicator percentage={data?.percentage} />

          <List>
            {'year' in product.attributes && (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <WineBar />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  A product of the year&nbsp;
                  {product.attributes.year as number}
                </ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Public />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                From&nbsp;
                {'region' in product.attributes
                  ? [product.attributes.region, product.attributes.country]
                    .filter((val) => !!val && val !== '-')
                    .join(', ')
                  : product.attributes.country}
              </ListItemText>
            </ListItem>
          </List>

          <Button
            variant="outlined"
            fullWidth
            size="large"
            sx={{ marginTop: 2 }}
            endIcon={<AddShoppingCart />}
          >
            €
            {product.price?.toFixed(2)}
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={2}
        sx={{
          borderRadius: 2,
          backgroundColor: 'rgb(0,0,0,0.02)',
          marginTop: 6,
          marginBottom: 8,
          paddingTop: 2,
          paddingBottom: 4,
        }}
      >
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Shield />
          <Typography>Secure Shopping Experience</Typography>
        </Grid>

        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <RestartAlt />
          <Typography>
            Don&apos;t like the product? Return it within 30 days!
          </Typography>
        </Grid>

        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <LocalShipping />
          <Typography>Ordered today? Delivered tomorrow!</Typography>
        </Grid>
      </Grid>

      <Typography variant="h1" sx={{ marginBottom: 2 }}>
        Reviews
      </Typography>

      {reviews.map((review) => (
        <Box sx={{ marginBottom: 4 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              marginBottom: 1,
            }}
          >
            <Avatar {...stringAvatar(review.name)} />
            <div>
              <Typography>{review.name}</Typography>

              <Rating readOnly value={review.rating} />
            </div>
          </Box>

          <Typography>{review.text}</Typography>
        </Box>
      ))}

      <Typography variant="h2" sx={{ marginBottom: 2, marginTop: 8 }}>
        Leave a review
      </Typography>
      <TextField
        label="Did you like this product? Did it match with your taste profile?"
        fullWidth
        rows={2}
        multiline
      />

      <Button disabled variant="contained" sx={{ marginTop: 2 }}>
        Send
      </Button>
    </Container>
  );
}

export default Product;
