import { OpenInNew, YouTube } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import getLogo from '../utils/logo';

const pages = [
  { title: 'Home', link: '/' },
  { title: 'Do the test', link: '/questionnaire' },
  { title: 'My matches', link: '/my-matches' },
  {
    title: 'Hyper personalization',
    link: 'https://www.youtube.com/watch?v=6xfTKm5eNEA',
    external: true,
    youtube: true,
  },
  { title: 'Performance metrics', link: '/metrics' },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Container>
      <AppBar
        position="static"
        sx={{
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          boxShadow: 'none',
          backgroundColor: 'white',
          color: 'black',
          border: '1px dashed #ddd',
          borderTop: 0,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters style={{ height: '2rem' }}>
            <img
              src={getLogo()}
              className="logo"
              alt="Logo"
              style={{
                width: '4rem',
                height: '4rem',
                padding: '1rem',
                marginRight: '1rem',
              }}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                height: '100%',
                borderColor: 'primary.main',
              }}
            >
              {pages.map((page) => (
                <NavLink
                  key={page.link}
                  to={page.link}
                  style={({ isActive }) => ({
                    color: 'inherit',
                    textDecoration: 'none',
                    height: '100%',
                    display: 'flex',

                    borderBottomWidth: '2px',
                    borderBottomStyle: 'solid',
                    borderColor: isActive ? 'inherit' : 'transparent',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    transition: 'border-color 0.2s',
                  })}
                  target={page.external ? '_blank' : undefined}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                      color: 'inherit',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    {page.title}

                    {page.external && !page.youtube && (
                      <OpenInNew />
                    )}
                    {page.youtube && <YouTube color="error" />}
                  </Typography>
                </NavLink>
              ))}
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <NavLink
                    key={page.link}
                    to={page.link}
                    style={({ isActive }) => ({
                      color: 'inherit',
                      textDecoration: 'none',
                      height: '100%',
                      display: 'flex',

                      fontWeight: isActive ? 'bold' : undefined,
                      transition: 'border-color 0.2s',
                    })}
                    target={page.external ? '_blank' : undefined}
                  >
                    <MenuItem
                      key={page.title}
                      onClick={handleCloseNavMenu}
                      style={{
                        fontWeight: 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 8,
                      }}
                    >
                      <Typography
                        textAlign="center"
                        style={{ fontWeight: 'inherit' }}
                      >
                        {page.title}
                      </Typography>

                      {page.external && !page.youtube && (
                        <OpenInNew />
                      )}
                      {page.youtube && <YouTube color="error" />}
                    </MenuItem>
                  </NavLink>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="My account">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="Account" />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Container>
  );
}
export default ResponsiveAppBar;
