import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';
import { Store } from '@mui/icons-material';

function Footer() {
  return (
    <Container sx={{ marginTop: 8, paddingBottom: 8 }}>
      <Divider sx={{ marginY: 2 }} />

      <Box sx={{
        display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2,
      }}
      >
        <img src="./logo.png" className="logo" alt="Logo" />
        <Typography>
          Researched by scientists, implemented by food lovers and enjoyed by the general public.
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2,
      }}
      >
        <Store sx={{ width: '2rem' }} />
        <Typography>
          This is a demonstration store to showcase how TasteIndex would integrate with your store. No items in this store can be bought.
        </Typography>
      </Box>

    </Container>
  );
}
export default Footer;
