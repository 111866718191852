import { Box, Typography } from '@mui/material';

function HowDoesItWork() {
  return (
    <Box mt={4} textAlign="center">
      <Typography variant="h1" sx={{ marginBottom: 4 }}>How does it work?</Typography>
      <img src="https://images.squarespace-cdn.com/content/v1/55a3894be4b0bb17f7b55958/1450304665778-TZU3BL40UXZS41Y3FI34/image-asset.png" alt="" style={{ width: '100%', maxWidth: '1000px' }} />
    </Box>
  );
}

export default HowDoesItWork;
