import { Container, Typography } from '@mui/material';

function Questionnaire() {
  return (
    <Container>

      <Typography variant="h1" sx={{ marginBottom: 2 }}>Do the test and discover your taste profile.</Typography>
      <Typography variant="h2" sx={{ marginBottom: 4 }}>It only takes 45 seconds.</Typography>
      <iframe src={process.env.REACT_APP_FRONTEND_URL} style={{ border: 'none' }} title="Taste profile questionnaire" width="100%" height="620px" />
    </Container>
  );
}

export default Questionnaire;
