const getLogo = () => {
  /* @ts-ignore */
  const { searchParams } = new URL(document.location);
  const customerUrl = searchParams.get('customerUrl') ?? window.localStorage.getItem('customerUrl');

  // return '/logo.png';
  if (customerUrl) {
    window.localStorage.setItem('customerUrl', customerUrl);
    return `https://www.google.com/s2/favicons?sz=128&domain_url=${customerUrl}`;
  } if (searchParams.get('customerUrl') === '') {
    window.localStorage.removeItem('customerUrl');
  }
  return '/logo.png';
};

export default getLogo;
