import { Container } from '@mui/material';
import Hero from '../components/Hero';
import HowDoesItWork from '../components/HowDoesItWork';

function Home() {
  return (
    <Container>
      <Hero />
      <HowDoesItWork />
    </Container>
  );
}

export default Home;
