import productInfo from './products/productInfo';

type ProductMatch = {
  sku: string,
  name: string,
  percentage: number
};

const getToken = (searchParams: URLSearchParams) => {
  const tokenParam = searchParams.get('ti_demo_token');

  if (tokenParam) {
    window.localStorage.setItem('ti_demo_token', tokenParam);
  }

  return tokenParam || window.localStorage.getItem('ti_demo_token');
};

const getProductsWithMatchRating = async (searchParams: URLSearchParams) => {
  const token = getToken(searchParams);

  if (!token) return undefined;

  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v2/matches?token=${token}&clientId=${process.env.REACT_APP_CLIENT_ID}`;

  const result = await fetch(apiUrl);
  const resultJson : Array<ProductMatch> = await result.json();

  const productsWithMatches = productInfo.map((product) => ({
    ...product,
    matchPercentage: resultJson.find((productMatch) => productMatch.sku === product.sku)?.percentage ?? 0,
  }));
  productsWithMatches.sort((a, b) => b.matchPercentage - a.matchPercentage);

  return productsWithMatches;
};

export const getProductMatch = async (sku: string | undefined, searchParams: URLSearchParams) => {
  const token = getToken(searchParams);

  if (!token || !sku) return undefined;

  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v2/match?sku=${sku}&token=${token}&clientId=${process.env.REACT_APP_CLIENT_ID}`;

  const result = await fetch(apiUrl);
  const resultJson = await result.json() as ProductMatch;

  return resultJson;
};

export default getProductsWithMatchRating;
