import {
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import productInfo from '../products/productInfo';
import MatchPercentageIndicator from './MatchPercentageIndicator';

type ProductLeaderboardProps = {
  product: (typeof productInfo)[0] & { matchPercentage: number };
};

function ProductLeaderboard({ product }: ProductLeaderboardProps) {
  return (
    <Link to={`/products/${product.sku}`} style={{ textDecoration: 'none' }}>
      <Card sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
        <CardMedia
          component="img"
          height="160"
          image={product.image}
          sx={{ objectFit: 'contain' }}
        />
        <CardContent
          sx={{
            padding: '0 !important',
            marginTop: 4,
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          <Typography variant="h2" fontSize={{ xs: '1rem' }}>
            {product.name}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',

              flexDirection: { xs: 'column', md: 'row' },
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            <List
              sx={{
                textDecoration: 'none',
                margin: 0,
                padding: 0,
              }}
            >
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar>
                  <MatchPercentageIndicator
                    percentage={product.matchPercentage}
                  />
                </ListItemAvatar>
              </ListItem>
            </List>

            <Typography>
              {/* &#36; */}
              &euro;
              {product.price?.toFixed(2)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
}

export default ProductLeaderboard;
