/* eslint-disable @typescript-eslint/no-unused-vars */
import beers from './beers.json';
import fruit from './fruit.json';
import heinz from './heinz.json';
import herbs from './herbs.json';
import mm from './m_and_m.json';
import mars from './mars.json';
import nespresso from './nespresso.json';
import softDrinks from './soft-drinks.json';
import starbucks from './starbucks.json';
import tea from './tea.json';
import wineCs from './wine-cs.json';
import wineE from './wine-e.json';

export default [
  ...beers,
  ...fruit,
  ...heinz,
  ...herbs,
  ...mars,
  ...mm,
  ...nespresso,
  ...softDrinks,
  // ...starbucks,
  ...tea,
  ...wineCs,
  // ...wineE,
];
